import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import {
  Conversation,
  UserInfo,
  Post,
  HashtagInfo,
  KMSObject,
  PostSurvey,
  ITransaction,
  UserCategory,
  UserFavorites,
  ImageEntityTypes,
  ImageType,
  MapDoc
} from "../shared/entities";
import { UserService } from "./user.service";
import { map, catchError, filter, tap } from "rxjs/operators";
import { KMSSearchResults, SearchService } from "./search.service";
import { ConfigurationService } from "./configuration.service";
import { Guid } from "../shared/utils";
import { Utilities } from "src/app/shared/utils";
import { IkService } from "./ik.service";

@Injectable({
  providedIn: "root"
})
export class NewsfeedService {
  public reloadAttachments$: Subject<void> = new Subject();
  public updatedPost$: Subject<string>= new Subject();
  public postId:string = "";
  private defaultAvatar$: Observable<string>;
  public searchMode$ : BehaviorSubject<string> = new BehaviorSubject("starts");
  //this is helpful for closing the hashtag menu (bur96)
  public enterKeyPressed$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  public hashtag$ :BehaviorSubject<string> = new BehaviorSubject("");
  // public backSpaceKeyPressed$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  public activateMenu$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  public activateMenuUpdate$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  public activateMenuReply$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isReply$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isUpdateMode$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  public activateMentionSearchBar$ : Subject<boolean> = new Subject();
  public triggerCharacter$ :BehaviorSubject<string> = new BehaviorSubject<string>("");
  public mentionSelected$ : Subject<any> = new Subject<any>();
  public mentionToModify$ : BehaviorSubject<string> = new BehaviorSubject<string>("");
  public mentionsSelected$ : BehaviorSubject<Map<string,any>> = new BehaviorSubject<Map<string,any>>(new Map<string,any>());
  public mentionsToInsert$ : BehaviorSubject<Map<string,any>> = new BehaviorSubject<Map<string,any>>(new Map<string,any>());
  public hashtagSelected$ : Subject<HashtagInfo[]> = new Subject<HashtagInfo[]>();
  //use behaviuor subjcet in this case: getParams() in newsfeed component is inside on Init
  public newsfeedConversation$ : BehaviorSubject<Conversation[]> = new BehaviorSubject<Conversation[]>(null);

  constructor(
    private httpClient: HttpClient,
    private userSvc: UserService,
    private ikSvc: IkService,
    private readonly searchSvc: SearchService,
    private readonly configSvc: ConfigurationService
  ) {
    this.configSvc.assetsConfig.pipe(filter(r => r != null)).subscribe(() => {
      this.defaultAvatar$ = this.configSvc.getDefaultImage(
        ImageEntityTypes.Conversation,
        ImageType.Avatar,
        `portal/api/newsfeed/avatar/default/get`
      );
    });
  }

  public getMyConversations(
    params: URLSearchParams = new URLSearchParams()
  ): Observable<Conversation[]> {
    return this.httpClient.get<Conversation[]>(
      `newsfeed/api/Newsfeed/UserConversations?${params}`
    ).pipe(
      map(
        /**
         * Questa parte di codice è stata aggiunta per aggirare un bug sull'operazione di 
         * demote, nel caso in cui nella conversation sia presente un ik che è stato 
         * cancellato non lo mostra a schermo. DA FARE: individuare la parte del BE che 
         * aggiorna le conversation a seguito dell'operazione di demote.
         */
         (response: Conversation[]) => {
          let conversationsCopy = response;

          //ciclo sulle singole conversazioni
          response.forEach((conversation: Conversation) => {
            let conversationId: string = conversation.rootPostId;

            //se trovo una ik valorizzata
            if (conversation.ik != null) {

              // recupero la ik per verificare se esiste;
              this.ikSvc.getIkById(conversation.ik.id).subscribe(
                (ik) => {

                  //se la ik risulta essere stata cancellata
                  if (ik === null) {
                    //questa operazione modifica l'array originale eliminando a visualizzazione la ik
                    let brokenConversation = conversationsCopy.find((elem) => conversationId === elem.rootPostId);
                    brokenConversation ? brokenConversation.ik = null : null;
                  }
                }
              );
            }
          })
          return conversationsCopy;
        }
      )
    )
  }

  public getNetworkConversation(
    hashtagId: string,
    params: URLSearchParams = new URLSearchParams()
  ): Observable<Conversation[]> {
    if (this.userSvc.isGuest) {
      return this.httpClient.get<Conversation[]>(
        `external/api/network/newsfeed/${hashtagId}?${params}`
      );
    } else {
      return this.httpClient.get<Conversation[]>(
        `newsfeed/api/newsfeed/hashtagconversation/audience/${hashtagId}?${params}`
      );
    }
  }

  public getHashtagConversation(
    hashtagId: string,
    params: URLSearchParams = new URLSearchParams()
  ): Observable<Conversation[]> {
    return this.httpClient.get<Conversation[]>(
      `newsfeed/api/newsfeed/hashtagconversation/contains/${hashtagId}?${params}`
    );
  }

  getChallengeConversation(
    hashtagChallengeId: string,
    IdeaIdList: string[],
    params: URLSearchParams = new URLSearchParams()
  ): Observable<Conversation[]> {
    return this.httpClient.post<Conversation[]>(
      `newsfeed/api/newsfeed/conversations/challenges/${hashtagChallengeId}?${params}`,
      IdeaIdList
    );
  }

  public getConversationById(
    rootPostId: string,
    params: URLSearchParams = new URLSearchParams(),
    showDeleted = false
  ): Observable<Conversation> {
    if (this.userSvc.userCategory === UserCategory.Guest) {
      return this.httpClient.get<Conversation>(
        `external/api/network/Conversation/${rootPostId}?${params}`
      );
    } else {
      if (showDeleted) {
        return this.httpClient.get<Conversation>(
          `newsfeed/api/newsfeed/Conversation/${rootPostId}/true?${params}`
        ).pipe(
          /**
           * Questa parte di codice è stata aggiunta per aggirare un bug sull'operazione di 
           * demote, nel caso in cui nella conversation sia presente un ik che è stato 
           * cancellato non lo mostra a schermo. DA FARE: individuare la parte del BE che 
           * aggiorna le conversation a seguito dell'operazione di demote.
           */
          map((conversation: Conversation) => {
              
              //se trovo una ik valorizzata
              if (conversation.ik != null) {

                // recupero la ik per verificare se esiste;
                this.ikSvc.getIkById(conversation.ik.id).subscribe(
                  (ik) => {

                    //se la ik risulta essere stata cancellata
                    if (ik === null) {
                      //modifico la conversation
                      conversation.ik = null
                    }
                  }
                );
              }
              return conversation;
            }
          )
        )
      } else {
        return this.httpClient.get<Conversation>(
          `newsfeed/api/newsfeed/Conversation/${rootPostId}?${params}`
        ).pipe(
          /**
           * Questa parte di codice è stata aggiunta per aggirare un bug sull'operazione di 
           * demote, nel caso in cui nella conversation sia presente un ik che è stato 
           * cancellato non lo mostra a schermo. DA FARE: individuare la parte del BE che 
           * aggiorna le conversation a seguito dell'operazione di demote.
           */
          map((conversation: Conversation) => {
              
              //se trovo una ik valorizzata
              if (conversation.ik != null) {

                // recupero la ik per verificare se esiste;
                this.ikSvc.getIkById(conversation.ik.id).subscribe(
                  (ik) => {

                    //se la ik risulta essere stata cancellata
                    if (ik === null) {
                      //modifico la conversation
                      conversation.ik = null
                    }
                  }
                );
              }
              return conversation;
            }
          )
        )
      }
    }
  }

  public isPostStatusById(id: string): Observable<Number> {
    return this.httpClient.get<Number>(
      `newsfeed/api/newsfeed/Conversation/status/${id}`
    );
  }


  public getNetworkByConversation(rootPostId: string): Observable<any> {
    if (this.userSvc.userCategory === UserCategory.Guest) {
      return this.httpClient.get(
        `external/api/network/Conversation/networkDetail/${rootPostId}`
      );
    } else {
      return this.httpClient.get(
        `portal/api/newsfeed/Conversation/networkDetail/${rootPostId}`
      );
    }
  }

  public searchNetwork(query: string): Observable<HashtagInfo[]> {
    const params = {
      filter: "isPrivate eq false and status eq 'Published'",
      searchFields: ["name", "hashtag"]
    };
    return this.searchSvc.query("Networks", query || "", params).pipe(
      catchError(() => of([])),
      map((searchRes: KMSSearchResults<any>) =>
        searchRes.results
          .map(result => result.document)
          .map(val => MapDoc.docToNetInfo(val))
          .map(n => {
            n.hashtag.description = n.name;
            return n.hashtag;
          })
          //.sortBy(x => x.description)
      )
    );
  }

  public searchAudience(query: string): Observable<HashtagInfo[]> {
    const params = {
      filter: "(typeId eq 1 or typeId eq 2) and isPrivate eq false and status eq 'Published'",
      searchFields: ["name", "hashtag"]
    };
    return this.searchSvc.query("Networks", query || "", params).pipe(
      catchError(() => of([])),
      map((searchRes: KMSSearchResults<any>) =>
        searchRes.results
          .map(result => result.document)
          .map(val => MapDoc.docToNetInfo(val))
          .map(n => {
            n.hashtag.description = n.name;
            return n.hashtag;
          })
          //.sortBy(x => x.description)
      )
    );
  }

  public startWithSearch():void{
    this.searchMode$.next("starts");
  }

  public containsSearch():void{
    this.searchMode$.next("contains");
  }

  public searchPuntualUser(query: string, isPrivate = false): Observable<UserInfo[]> {

   query = `"${query}"`;
    const params = {
      filter:
        "search.ismatch('" +
        query +
        "', '" +
        "name" +
        "', 'full')",
      searchFields: ["name"],
      top: 10
    };

    return this.searchSvc
      .query("Users", query, {
        searchFields: ["name"],
        params
      })
      .pipe(
        catchError(() => of([])),
        map((searchRes: KMSSearchResults<any>) =>
          searchRes.results
            .map(result => result.document)
            .map(val => MapDoc.docToUserInfo(val))
        )
      );
  }

  public searchUser(query: string, isPrivate = false): Observable<UserInfo[]> {
    // if (!isPrivate) {
    return this.searchSvc
      .query("Users", query, {
        searchFields: ["name", "email", "lastName", "firstName"]
      })
      .pipe(
        catchError(() => of([])),
        map((searchRes: KMSSearchResults<any>) =>
          searchRes.results
            .map(result => result.document)
            .map(val => MapDoc.docToUserInfo(val))
        )
      );
    // } else {
    //   return this.externalNetworkSvc
    //     .getPeopleByNetworkId()
    //     .pipe(
    //       map(users =>
    //         users.filter(user =>
    //           query
    //             ? user.displayName.toLowerCase().includes(query.toLowerCase())
    //             : true
    //         )
    //       )
    //     );
    // }
  }

  public like(postId: string): Observable<ITransaction> {
    console.log("nel richiamo dell'endpoint da FE")
    if (this.userSvc.isGuest) {
      return this.httpClient.post<ITransaction>(
        `external/api/network/post/${postId}/like`,
        null
      );
    } else {
      return this.httpClient.post<ITransaction>(
        `portal/api/newsfeed/post/${postId}/like`,
        null
      );
    }
  }

  //METODO PER LIKE SS
  public likeSS(ssId: string): Observable<ITransaction> {
    console.log("likeSS");
    if(this.userSvc.isGuest){
      //logica per guest
      return this.httpClient.post<ITransaction>(
        `external/api/network/successtory/${ssId}/like`,
        null
      )
    }else{

      return this.httpClient.post<ITransaction>(
        `portal/api/newsfeed/successtory/${ssId}/like`,
        null
      )

    }
  }

  public unlikeSS(postId: string): Observable<ITransaction> {
    console.log("unlikeSS");
    if (this.userSvc.isGuest) {
      return this.httpClient.post<ITransaction>(
        `external/api/network/successtory/${postId}/unlike`,
        null
      );
    } else {
      return this.httpClient.post<ITransaction>(
        `portal/api/newsfeed/successtory/${postId}/unlike`,
        null
      );
    }
  }

  public getLikeSS(postId: string): Observable<any>{
    return this.httpClient.post(
      `portal/api/newsfeed/getSSlike/${postId}`,
      null
    );

  }

  public getLikesSS(postIds: string[]): Observable<any>{
    var response = this.httpClient.post(
      `portal/api/newsfeed/getSSlike`,
      postIds
    );
    return response;

  }
  //METODO PER LIKE SS


  public trackPoolVoteEvent(
    scope: string,
    targetId: string,
    targetUserId: string
  ): Observable<any> {
    // console.log("trackPoolVoteEvent: "+targetId);
    return this.httpClient.post(
      "portal/api/newsfeed/" + targetId + "/pollvote",
      {
        item1: scope,
        item2: targetUserId,
      }
    );
  }

  public trackAudienceAdd(
    audiences: string[],
    targetId: string,
    targetUserId: string
  ): Observable<any> {
    // console.log("trackAudienceAdd: "+targetId);

    return this.httpClient.post(
      "newsfeed/api/newsfeed/" + targetId + "/addaudiencesevent",
      {
        item1: audiences,
        item2: targetUserId,
      }
    );
  }

  public unlike(postId: string): Observable<ITransaction> {
    if (this.userSvc.isGuest) {
      return this.httpClient.post<ITransaction>(
        `external/api/network/post/${postId}/unlike`,
        null
      );
    } else {
      return this.httpClient.post<ITransaction>(
        `portal/api/newsfeed/post/${postId}/unlike`,
        null
      );
    }
  }

  public upVote(postId: string): Observable<ITransaction> {
    if (this.userSvc.isGuest) {
      return this.httpClient.post<ITransaction>(
        `external/api/network/post/${postId}/vote`,
        null
      );
    } else {
      return this.httpClient.post<ITransaction>(
        `portal/api/newsfeed/post/${postId}/vote`,
        null
      );
    }
  }

  public downVote(postId: string): Observable<ITransaction> {
    if (this.userSvc.isGuest) {
      return this.httpClient.post<ITransaction>(
        `external/api/network/post/${postId}/unvote`,
        null
      );
    } else {
      return this.httpClient.post<ITransaction>(
        `portal/api/newsfeed/post/${postId}/unvote`,
        null
      );
    }
  }

  public ensureHashtags(list: any[], networkId?: string): Observable<any[]> {
    if (this.userSvc.isGuest) {
      return this.httpClient.post<any[]>(
        `external/api/network/${networkId}/hashtag`,
        list
      );
    } else {
      return this.httpClient.post<any[]>(`portal/api/Hashtag/createmany`, list);
    }
  }

  public delete(postId: string): Observable<any> {
    if (this.userSvc.isGuest) {
      return this.httpClient.delete(
        `external/api/network/post/${postId}/delete`
      );
    } else {
      return this.httpClient.delete(
        `portal/api/newsfeed/post/${postId}/delete`
      );
    }
  }

  public checkIfFollowed(
    objectFollowedId: string,
    typeKey: number
  ): Observable<boolean> {
    return this.httpClient.get<boolean>(
      "portal/api/newsfeed/checkIfFollowed/" + objectFollowedId + "/" + typeKey
    );
  }

  public follow(
    objectFollowedId: string,
    typeKey: KMSObject
  ): Observable<ITransaction> {
    if (KMSObject[typeKey] === "User") {
      return this.httpClient.post<ITransaction>(
        `portal/api/user/${KMSObject[typeKey]}s/${objectFollowedId}/follow`,
        null
      );
    } else {
      return this.httpClient.post<ITransaction>(
        `portal/api/user/Hashtags/${objectFollowedId}/follow`,
        null
      );
    }
  }

  public unfollow(
    objectFollowedId: string,
    typeKey: KMSObject
  ): Observable<ITransaction> {
    if (KMSObject[typeKey] === "User") {
      return this.httpClient.post<ITransaction>(
        `portal/api/user/${KMSObject[typeKey]}s/${objectFollowedId}/unfollow`,
        null
      );
    } else {
      return this.httpClient.post<ITransaction>(
        `portal/api/user/Hashtags/${objectFollowedId}/unfollow`,
        null
      );
    }
  }

  public createPost(content: Post) {
    return this.httpClient.post<ITransaction>(
      "portal/api/newsfeed/post/create",
      content
    );
  }

  public createPostWithSurvey(post: Post, survey: PostSurvey) {
    return this.httpClient.post<ITransaction>(
      "portal/api/newsfeed/post/survey/create",
      {
        post,
        survey
      }
    );
  }

  public createPost2(post: Post, survey: PostSurvey, attachments: File[]) {
    const payload = new FormData();
    var jsonPost = JSON.stringify(post);
    if(jsonPost.length == 0)
      alert("Something went wrong before sending ");

    payload.append("post", jsonPost);
    if (survey) {
      var jsonSurvey = JSON.stringify(survey);
      payload.append("survey", jsonSurvey);
    }
    attachments.forEach(x => payload.append("file", x));

    return this.httpClient.post<ITransaction>(
      "portal/api/newsfeed/post",
      payload,
    );
  }

  public updatePost(post: Post, survey: PostSurvey, attachments: File[]) {
    const payload = new FormData();
    var jsonPost = JSON.stringify(post);
    if(jsonPost.length == 0)
      alert("Something went wrong before sending ");

    payload.append("post", jsonPost);
    if (survey) {
      var jsonSurvey = JSON.stringify(survey);
      payload.append("survey", jsonSurvey);
    }
    attachments.forEach(x => payload.append("file", x));

    return this.httpClient.post<ITransaction>(
      "portal/api/newsfeed/updatepost",
      payload,
    );
  }

  public createPostExternal(
    post: Post,
    survey: PostSurvey,
    attachments: File[]
  ) {
    const payload = new FormData();
    payload.append("post", JSON.stringify(post));
    if (survey) {
      payload.append("survey", JSON.stringify(survey));
    }
    attachments.forEach(x => payload.append("file", x));

    return this.httpClient.post<ITransaction>(
      "external/api/network/post",
      payload
    );
  }

  public submitSurveyResponse(response: any) {
    if (this.userSvc.isGuest) {
      return this.httpClient.post<any>(
        "external/api/network/post/survey/response",
        response
      );
    } else {
      return this.httpClient.post<any>(
        "portal/api/newsfeed/post/survey/response",
        response
      );
    }
  }

  public modifyAudience(postId: string, audiences: string[]): Observable<any> {
    return this.httpClient.post<ITransaction>(
      `portal/api/newsfeed/post/audience/${postId}`,
      audiences
    );
  }

  public removeAudience(postId: string, audiences: string[]): Observable<any> {
    return this.httpClient.post<ITransaction>(
      `portal/api/newsfeed/post/removeaudience/${postId}`,
      audiences
    );
  }

  public changePostAuthor(postId: string, authorId: string) :Observable<any>{
    return this.httpClient.post<ITransaction>(
      `portal/api/newsfeed/post/${postId}/author`,
      "\""+authorId+"\""
    );
  }

  public changePostType(postId: string, newType: number) :Observable<any>{
    return this.httpClient.post<ITransaction>(
      `portal/api/newsfeed/post/${postId}/changetype`,
      "\""+newType+"\""
    );
  }

  addConversationToFavorites(rootpostId: string) {
    return this.httpClient.post<ITransaction>(
      "portal/api/user/favourites/" +
        KMSObject.Conversation +
        "/" +
        rootpostId +
        "/add",
      null
    );
  }

  removeConversationToFavorites(rootpostId: string) {
    return this.httpClient.post<ITransaction>(
      "portal/api/user/favourites/" + rootpostId + "/remove",
      null
    );
  }

  getFavoritesId(): Observable<UserFavorites> {
    return this.httpClient.get<UserFavorites>("portal/api/user/favourites");
  }

  getFavoriteConversations(rootPostIds: string[]) {
    if (this.userSvc.userCategory === UserCategory.Guest) {
      return this.httpClient.post(
        `external/api/network/conversations`,
        rootPostIds
      );
    } else {
      return this.httpClient.post(
        "portal/api/newsfeed/conversations",
        rootPostIds
      );
    }
  }

  public KOVote(postId: string): Observable<ITransaction> {
    return this.httpClient.post<ITransaction>(
      `portal/api/newsfeed/post/${postId}/KO/vote`,
      null
    );
  }

  public KODownVote(postId: string): Observable<ITransaction> {
    return this.httpClient.post<ITransaction>(
      `portal/api/newsfeed/post/${postId}/KO/unvote`,
      null
    );
  }

  public KOVoteSS(SuccessStoryId: string): Observable<ITransaction> {
    return this.httpClient.post<ITransaction>(
      `portal/api/newsfeed/successStory/${SuccessStoryId}/KO/vote`,
      null
    );
  }

  public KODownVoteSS(SuccessStoryId: string): Observable<ITransaction> {
    return this.httpClient.post<ITransaction>(
      `portal/api/newsfeed/successStory/${SuccessStoryId}/KO/unvote`,
      null
    );
  }

  public upVoteSS(SuccessStoryId: string): Observable<ITransaction> {
    if (this.userSvc.isGuest) {
      return this.httpClient.post<ITransaction>(
        `external/api/network/successStory/${SuccessStoryId}/vote`,
        null
      );
    } else {
      return this.httpClient.post<ITransaction>(
        `portal/api/newsfeed/successStory/${SuccessStoryId}/vote`,
        null
      );
    }
  }

  public downVoteSS(SuccessStoryId: string): Observable<ITransaction> {
    if (this.userSvc.isGuest) {
      return this.httpClient.post<ITransaction>(
        `external/api/network/successStory/${SuccessStoryId}/unvote`,
        null
      );
    } else {
      return this.httpClient.post<ITransaction>(
        `portal/api/newsfeed/successStory/${SuccessStoryId}/unvote`,
        null
      );
    }
  }

  public getSuccessStoryVote(SuccessStoryId: string): Observable<any> {
    return this.httpClient.post<ITransaction>(`portal/api/newsfeed/getSSVotes/${SuccessStoryId}`,
    null);
  }

  public getSuccessStoryVotes(successStoryIds: string[]): Observable<any> {
    return this.httpClient.post<ITransaction>(`portal/api/newsfeed/getSSVotes`,
      successStoryIds);
  }

  public getSuggestionsForUser(userGuidList: string[]) {
    return this.httpClient.post<UserInfo[]>(
      "search/api/search/recommend/people",
      userGuidList
    );
  }

  public getSuggestionsForHashtag(hashGuidList: string[]) {
    return this.httpClient.post<HashtagInfo[]>(
      "search/api/search/recommend/hashtag",
      hashGuidList
    );
  }

  public getSuggestionsForNetwork(netGuidList: string[]) {
    return this.httpClient.post<any>(
      "search/api/search/recommend/audience",
      netGuidList
    );
  }

  public getDefaultConversationAvatar(): Observable<string> {
    return this.defaultAvatar$;
  }

  public getPreviewURL(url: string): Observable<any> {
    return this.httpClient.post(
      "portal/api/attachments/filepreview",
      JSON.stringify(url),
      {
        responseType: "text"
      }
    );
  }

  public getMaxDate(conversation: Conversation) {
    const dateList = [conversation.rootPost.modified ? new Date(conversation.rootPost.modified) : new Date(conversation.rootPost.created)];
    if (conversation.replies && conversation.replies.length) {
      dateList.push(...conversation.replies.filter(r => r.modified).map(r => new Date(r.modified)));
    }
    if (conversation.responses && conversation.responses.length) {
      dateList.push(...conversation.responses.filter(r => r.modified).map(r => new Date(r.modified)));
    }
    return dateList.reduce((a, b) => a > b ? a : b);
  }
}
