import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  NetworkType,
  NetworkInfo,
  AISearchTelemetry,
} from "../shared/entities";
import { Observable, of } from "rxjs";
import { map, take } from "rxjs/operators";
import { merge } from "lodash-es";
import { Guid } from "../shared/utils";
import { WelcomeExperienceService } from "./first-access.service";

@Injectable({
  providedIn: "root",
})
export class SearchService {
  constructor(
    private readonly client: HttpClient,
    private readonly welcomeService: WelcomeExperienceService
  ) {}

  public queryWithExternal<T>(query: string, params: any = {}) {
      query = query.replace(/([&:])/g, '');


    return this.client.post<KMSSearchResults<T>>(
      `search/api/search/getNetWithExt`,
      {
        item1: this.buildQuery(query),
        item2: { ...this.defaultQueryParameters, ...params },
      }
    );
  }

  public query<T>(indexName: IndexName, query: string, params: any = {}) {
    indexName = indexName.toString().replace("-", "") as IndexName;
      query = query.replace(/([&:])/g, '');


    // if (!isAdvancedMode) {
    if (indexName == "Hashtags") {
      //otherwise sometimes the exact term is not retrieved (probably due to improper az search scoring..)
      query = query + "|" + this.buildQuery(query);
    } else {
      query = this.buildQuery(query);
    }

    return this.client.post<KMSSearchResults<T>>(
      `search/api/search/query/${indexName}`,
      {
        //item1: this.buildQuery(query),
        item1: query,
        item2: { ...this.defaultQueryParameters, ...params },
      }
    );

    // } else {
    //   return this.client.post<KMSSearchResults<T>>(
    //     `search/api/search/query/advanced/${indexName}`,
    //     {
    //       item1: query,
    //       item2: {
    //         ...{
    //           queryType: "simple"
    //         },
    //         ...params
    //       }
    //     }
    //   );
    // }
  }

  public queryPrivateNetworks<T>(
    indexName: IndexName,
    query: string,
    params: any = {}
  ) {
      query = query.replace(/([&:])/g, '');


    return this.client.post<KMSSearchResults<T>>(
      `search/api/search/queryPrivateNetworks/${indexName}`,
      {
        item1: this.buildQuery(query),
        item2: { ...this.defaultQueryParameters, ...params },
      }
    );
  }

  public guestQuery<T>(indexName: IndexName, query: string, params: any = {}) {
    var newIndexName = indexName.toString().replace("-", "");
      query = query.replace(/([&:])/g, '');


    //alert(newIndexName);
    if (newIndexName == "Hashtags") {
      //otherwise sometimes the exact term is not retrieved (probably due to improper az search scoring..)
      query = query + "|" + this.buildQuery(query);
    } else {
      query = this.buildQuery(query);
    }
    query = query.replace(/([(){}\[\]:])/g, '\\$1');
    return this.client.post<KMSSearchResults<T>>(
      `search/api/guestsearch/Query/${newIndexName}`,
      {
        item1: query,
        item2: { ...this.defaultQueryParameters, ...params },
      }
    );
  }

  public saveSearchTelemetry(userId: string, telemetry: AISearchTelemetry) {
    return this.client.post<AISearchTelemetry>(
      `search/api/AITelemetry/saveSearchTelemetry/${userId}`,
      telemetry
    );
  }

  public saveIKClickTelemetry(userId: string, ikId: Guid) {
    return this.client.post<AISearchTelemetry>(
      `search/api/AITelemetry/saveIKClickTelemetry/${userId}/${ikId}`,
      null
    );
  }

  public saveSuggestionClickTelemetry(userId: string, suggestion: string) {
    return this.client.post<AISearchTelemetry>(
      `search/api/AITelemetry/saveSuggestionClickTelemetry/${userId}`,
      { text: suggestion }
    );
  }

  public saveWelcomePeapleTelemetry(userId: string, peaple: string[]) {
    return this.client.post<AISearchTelemetry>(
      `search/api/AITelemetry/saveWelcomePeapleTelemetry`,
      { userId: userId, peaple: peaple }
    );
  }

  public saveWelcomePeapleTelemetryClick(userId: string, peaple: string[]) {
    return this.client.post<AISearchTelemetry>(
      `search/api/AITelemetry/saveWelcomePeapleTelemetryClick`,
      { userId: userId, peaple: peaple }
    );
  }

  public queryExternalSite<T>(
    query: string,
    site: string,
    existingFacets: any[]
  ) {
      query = query.replace(/([&:])/g, '');


    //alert("existingFacet "+existingFacets);
    return this.client.post<KMSSearchResults<T>>(
      `search/api/search/query/externalsourcequery`,
      {
        Query: query,
        ExternalSite: site,
        ExistingFacets: existingFacets,
      }
    );
  }

  public getSourcesList() {
    return this.client.get<any>(`search/api/search/externalsourceslist`);
  }

  public queryAdvanced<T>(
    searchInPrivate: boolean,
    extra: boolean,
    indexName: IndexName,
    query: string,
    params: any = {}
  ) {
      query = query.replace(/([&:])/g, '');


    var newIndexName = indexName.toString().replace("-", "");
    //alert(newIndexName);

    var item2 = { ...this.defaultQueryParameters, ...params };

    if (indexName === "lessonlearned") {
      if (item2.filter.includes("Deleted")) {
        this.welcomeService.welcomeExperienceStatusProfile
          .pipe(take(1))
          .subscribe((esp) => {
            const roles = esp?.item2?.roles;
            const displayName = esp?.item2?.displayName;

            if (!roles.admin && !roles.kmTeam) {
              item2 = {
                ...item2,
                filter: item2.filter.replace(
                  "status eq 'Deleted'",
                  `(status eq 'Deleted' and (author eq '${displayName}'))`
                ),
              };
            }
          });
      }
    }

    return this.client.post<KMSSearchResults<T>>(
      `search/api/search/query/${newIndexName}`,
      {
        item1: query,
        item2: item2,
        item3: searchInPrivate,
        item4: extra,
      }
    );
  }

  public guestQueryAdvanced<T>(
    indexName: IndexName,
    query: string,
    params: any = {}
  ) {
      query = query.replace(/([&:])/g, '');


    var newIndexName = indexName.toString().replace("-", "");
    //alert(newIndexName);
    return this.client.post<KMSSearchResults<T>>(
      `search/api/guestsearch/query/${newIndexName}`,
      {
        item1: query,
        item2: { ...this.defaultQueryParameters, ...params },
      }
    );
  }

  public queryRaw<T>(indexName: IndexName, query: string, params: any = {}) {
      query = query.replace(/([&:])/g, '');


    return this.client.post<KMSSearchResults<T>>(
      `search/api/search/query/${indexName}`,
      {
        item1: `${query.replace(/^#/, "")}*`,
        item2: { ...params },
      }
    );
  }

  public startsWith<T>(indexName: IndexName, query: string, params: any = {}) {
      query = query.replace(/([&:])/g, '');


    query = query.split(" ")[0];
    if (query.includes("'")) {
      query = ' "' + query + '*"';
    } else {
      query += "*";
    }

    return this.client.post<KMSSearchResults<T>>(
      `search/api/search/query/${indexName}`,
      {
        item1: `${query.replace(/^#/, "")}`,
        item2: { ...params },
      }
    );
  }

  public searchIn<T>(
    indexName: IndexName,
    terms: string[],
    params: any = null
  ) {
    let searchTerms = "";
    for (let i = 0; i < terms.length; i++) {
      if (terms[i].includes("'")) {
        searchTerms += ' "' + terms[i] + '"';
      } else if (i < terms.length - 1 && !terms[i].includes("'")) {
        searchTerms += "/.*" + terms[i] + ".*/ AND ";
      } else if (i >= terms.length - 1 && !terms[i].includes("'")) {
        searchTerms += "/.*" + terms[i] + ".*/";
      }
    }
    let query = `${searchTerms}`;
    return this.client.post<KMSSearchResults<T>>(
      `search/api/search/query/${indexName}`,
      {
        item1: `${query.replace(/^#/, "")}`,
        item2: { ...params },
      }
    );
  }

  public externalQueryStartsWith<T>( // not to be confused with "queryExternalSite"
    indexName: IndexName,
    query: string,
    params: any = {}
  ) {
      query = query.replace(/([&:])/g, '');


    query = query.split(" ")[0];
    if (query.includes("'")) {
      query = ' "' + query + '*"';
    } else {
      query += "*";
    }
    return this.client.post<KMSSearchResults<T>>(
      `external/api/search/query/${indexName}`,
      {
        item1:  `${query.replace(/^#/, "")}`,
        item2: { ...this.defaultQueryParameters, ...params },
      }
    );
  }

  public externalQuerySearchin<T>( // not to be confused with "queryExternalSite"
  indexName: IndexName,
  terms: string[],
  params: any = null
  ) {
    let searchTerms = "";
    for (let i = 0; i < terms.length; i++) {
      if (terms[i].includes("'")) {
        searchTerms += ' "' + terms[i] + '"';
      } else if (i < terms.length - 1 && !terms[i].includes("'")) {
        searchTerms += "/.*" + terms[i] + ".*/ AND ";
      } else if (i >= terms.length - 1 && !terms[i].includes("'")) {
        searchTerms += "/.*" + terms[i] + ".*/";
      }
    }
    let query = `${searchTerms}`;

    return this.client.post<KMSSearchResults<T>>(
      `external/api/search/query/${indexName}`,
      {
        item1:  `${query.replace(/^#/, "")}`,
        item2: { ...this.defaultQueryParameters, ...params },
      }
    );
  }

  public externalQuery<T>( // not to be confused with "queryExternalSite"
    indexName: IndexName,
    query: string,
    params: any = {}
  ) {
      query = query.replace(/([&:])/g, '');


    return this.client.post<KMSSearchResults<T>>(
      `external/api/search/query/${indexName}`,
      {
        item1: this.buildQuery(query),
        item2: { ...this.defaultQueryParameters, ...params },
      }
    );
  }

  public suggest<T>(
    indexName: IndexName,
    suggester: string,
    query: string,
    params: any = {}
  ) {

    return this.client.post<KMSSearchResults<T>>(
      `search/api/search/suggest/${indexName}/${suggester}`,
      {
        item1: this.buildQuery(query),
        item2: { ...this.defaultQueryParameters, ...params },
      }
    );
  }

  public autocomplete<T>(
    indexName: IndexName,
    suggester: string,
    query: string,
    params: any = {}
  ) {
      query = query.replace(/([&:])/g, '');



    const autocompleteQuery = query.replace(/\B\./g, "");
    //alert("indexName "+indexName);

    if (
      indexName.toLowerCase() !== "attachments" &&
      indexName.toLowerCase() !== "externalsourcesites" &&
      indexName.toLowerCase() !== "technicalpublications"
    ) {
      return this.client.post<KMSSearchResults<T>>(
        `search/api/search/autocomplete/${indexName}/${suggester}`,
        {
          item1: autocompleteQuery === "" ? " " : autocompleteQuery,
          item2: { ...params },
        }
      );
    } else {
      return of({});
    }
  }

  public guestAutocomplete<T>(
    indexName: IndexName,
    suggester: string,
    query: string,
    params: any = {}
  ) {
    const autocompleteQuery = query.replace(/\B\./g, "");
    //alert("indexName "+indexName);

    if (
      indexName.toLowerCase() !== "attachments" &&
      indexName.toLowerCase() !== "externalsourcesites"
    ) {
      return this.client.post<KMSSearchResults<T>>(
        `search/api/guestsearch/AutoComplete/${indexName}/${suggester}`,
        {
          item1: autocompleteQuery === "" ? " " : autocompleteQuery,
          item2: { ...params },
        }
      );
    } else {
      return of({});
    }
  }

  public queryAll<T>(query: string, params: any = {}) {
    query = query.replace(/([&:])/g, '');


    return this.client.post<KMSSearchResults<T>>(
      `search/api/search/query/all`,
      {
        item1: this.buildQuery(query),
        item2: { ...this.defaultQueryParameters, ...params },
      }
    );
  }

  public getIndex(indexName: string) {
    if (indexName == "TechnicalPublications") {
      indexName = "Posts";
    }
    return this.client.get<any>(`search/api/search/index/${indexName}`);
  }
  public getGuestIndex(indexName: string) {
    return this.client.get<any>(`search/api/guestsearch/index/${indexName}`);
  }

  public postCreationSuggester(value: string) {
    return this.client.post<any>(
      "search/api/search/suggest/similaritems",
      `'${value}'`
    );
  }

  public mailValidate(emails: string[]) {
    return this.client
      .post(`search/api/search/resolve/participants`, emails)
      .toPromise();
  }

  public getRelatedAttachments(id: string): Observable<Array<any>> {
    return this.client.post<Array<any>>(
      `search/api/search/relatedattachments`,
      "'" + id + "'"
    );
  }

  buildQuery(query: string) {
    const regexQuote = /".*?"/g; // regex get word in quotes

    const plusPreceedBySpace = /\+\s?(["\w]+)/; // plus symbol preceded by white space
    const orPreceedBySpace = /\-\s?(["\w]+)/; // or symbol preceded by white space

    let result = "";
    const quotes = regexQuote.exec(query);
    const plus = plusPreceedBySpace.exec(query);
    const or = orPreceedBySpace.exec(query);

    // console.log("Searching: ");
    // console.log(quotes);
    // console.log(plus);
    // console.log(or);

    if (
      (quotes && quotes.length > 0) ||
      (plus && plus.length > 0) ||
      (or && or.length > 0)
    ) {
      // phrase contains quotes, + or |;
      // Should be used withSearchMode.all;

      if (quotes) {
        quotes.forEach((match) => {
          result += ` ${match}`;
        });
      }

      let m;
      while ((m = regexQuote.exec(query)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regexQuote.lastIndex) {
          regexQuote.lastIndex++;
        }

        m.forEach((match) => {
          result += ` ${match}`;
        });
      }

      let noquotes = query.replace(regexQuote, "");
      const regex = /([^\s]+)/g;

      if (noquotes) {
        noquotes = noquotes.replace(/\+\s/g, "+").replace(/\-\s/g, "-");
        const n = noquotes.split(" ");
        n.forEach((match) => {
          if (match != null && match !== "" && !match.includes('"')) {
            if (!match.includes("-") && !match.includes("+")) {
              result += `"${match}"`;
            } else {
              if (
                !(
                  (match.includes("-") || match.includes("+")) &&
                  match.length === 1
                )
              ) {
                // don't add single character if are + or -
                result += `${match}`;
              }
            }
          }
        });
      }

      return result;
    } else {
      // This is the default generic case leveraging RegEx-based queries
      // In order to work properly, some chars need to be trimmed away
      // NOTE: Should be used with SearchMode.any
      if (query != null) {
        const queryFiltered = query
          // Required to match hashtags, which are NOT stored with leading sharp sign
          .replace(/^#/g, "")
          // In this case, it means we have orphaned quotes, not properly surronding words
          // which need to be trimmed away, ot they will break Az Search RegEx parsing
          .replace('"', "")
          // Require by some autocomplete queries, which suggest name.lastname (from email addresses)
          // but . has special meaning in RegExes/<TERM>/g
          .replace(/\|/g, " ")
          .replace(/\./g, " ")
          .replace(/\+/g, " ")
          .replace(/\:/g, " ")
          .replace(/\&/g, " ")
          .replace(/\*/g, " ")
          .replace(/\//g, " ")
          .replace(/\[/g, " ")
          .replace(/\]/g, " ")
          .replace(/\(/g, " ")
          .replace(/\)/g, " ")
          .replace(/\?/g, " ")
          .replace(/\-/g, " ")
          .replace(/\~/g, " ")
          .split(/\s/g)
          // Skip empty words
          .filter((r) => r !== "" || r === query.trim())
          // Wrap with a "contains" generic RegEx
          .map((s) => `/.*${s}.*/`)
          .join(" ");
        return queryFiltered !== "" ? queryFiltered : "/.*.*/";
      } else {
        return query;
      }
    }
  }

  get defaultQueryParameters() {
    return {
      queryType: "full",
    };
  }
}

export type IndexName =
  | "Default"
  | "ExternalSourceSites"
  | "Hashtags"
  | "Users"
  | "Posts"
  | "Networks"
  | "ImpactingKnowledges"
  | "Attachments"
  | "Webinars"
  | "Bookings"
  | "PrivateDefault"
  | "PrivatePosts"
  | "LessonLearned"
  | "lessonlearned";

export interface KMSSearchResults<T> {
  facets: { [key: string]: Facet[] };
  results: SearchResult<T>[];
  count: number;
  coverage: number;
}

export interface SearchResult<T> {
  document: T;
  highlights: { [key: string]: string[] };
  score: number;
}

export interface Facet {
  type: FacetType;
  from;
  to;
  value;
  count: number;
}

export enum FacetType {
  //
  // Summary:
  //     The facet counts documents with a particular field value.
  Value = 0,
  //
  // Summary:
  //     The facet counts documents with a field value in a particular range.
  Range = 1,
}

export const SEARCH_SOURCE = {
  internal: 0,
  external: 1,
};

export const REFINERS_CONFIG = {
  privatedefault: [
    // { name: "type", label: "Type" },
    { name: "createdYear", label: "Creation Year" },
    { name: "created", label: "Creation Month" },
    { name: "author", label: "Author", count: 100 },
    { name: "privateNetworks", label: "Private Networks", count: 100 },
  ],
  default: [
    // { name: "type", label: "Type" },
    { name: "createdYear", label: "Creation Year" },
    { name: "created", label: "Creation Month" },
    { name: "author", label: "Author", count: 100 },
  ],
  /*guestDefault: [
    // { name: "type", label: "Type" },
    { name: "createdYear", label: "Creation Year" },
    { name: "created", label: "Creation Month" },
    { name: "author", label: "Author", count: 100 },
    { name: "privateNetworks", label: "Private Networks", count: 100 }
  ],*/
  ExternalSourceSites: [
    { name: "ExternalSource", label: "External Source Site" },
  ],
  users: [
    { name: "country", label: "Country", count: 20 },
    { name: "city", label: "City", count: 20 },
    { name: "company", label: "Company", count: 20 },
    { name: "businessUnit", label: "BU", count: 20 },
    { name: "isGuest", label: "Guest" },
    { name: "isExternalConsultant", label: "Is Consultant" },
  ],
  hashtags: [
    { name: "type", label: "Type" },
    { name: "createdYear", label: "Creation Year" },
    { name: "created", label: "Creation Month" },
  ],
  privateposts: [
    { name: "type", label: "Type" },
    { name: "createdYear", label: "Creation Year" },
    { name: "created", label: "Creation Month" },
    { name: "author", label: "Author" },
    { name: "hashtags", label: "Hashtags", count: 100 },
    { name: "mentions", label: "Mentions", count: 100 },
    { name: "audience", label: "Private Networks", count: 50 },
  ],
  posts: [
    { name: "type", label: "Type" },
    { name: "createdYear", label: "Creation Year" },
    { name: "created", label: "Creation Month" },
    { name: "author", label: "Author" },
    { name: "hashtags", label: "Hashtags", count: 100 },
    { name: "mentions", label: "Mentions", count: 100 },
    { name: "audience", label: "Audience", count: 50 },
    { name: "privateNetworks", label: "Private Networks", count: 100 },
  ],
  /*guestPosts: [
    { name: "type", label: "Type" },
    { name: "createdYear", label: "Creation Year" },
    { name: "created", label: "Creation Month" },
    { name: "author", label: "Author" },
    { name: "hashtags", label: "Hashtags", count: 100 },
    { name: "mentions", label: "Mentions", count: 100 },
    { name: "audience", label: "Audience", count: 50 },
    { name: "privateNetworks", label: "Private Networks", count: 100 }
  ],*/
  networks: [
    { name: "type", label: "Type" },
    { name: "status", label: "Status" },
    { name: "facilitators", label: "Facilitator", count: 100 },
    { name: "coFacilitators", label: "Co-Facilitator", count: 100 },
    { name: "focalPoints", label: "Focal Point", count: 100 },
  ],
  impactingknowledges: [
    { name: "type", label: "Type" },
    { name: "createdYear", label: "Creation Year" },
    { name: "created", label: "Creation Month" },
    { name: "status", label: "Status" },
    { name: "referenceYear", label: "IK Year" },
    { name: "promotingNetworks", label: "Promoting Network", count: 80 },
    { name: "involvedPeople", label: "Involved People", count: 100 },
    { name: "relatedHashtags", label: "Related Hashtags", count: 20 },
  ],
  attachments: [
    { name: "objectSubType", label: "Type" },
    { name: "objectCreatedYear", label: "Creation Year" },
    { name: "objectCreated", label: "Creation Month" },
    { name: "users", label: "Users", count: 100 },
    { name: "hashtags", label: "Hashtags", count: 20 },
    { name: "fileType", label: "File Type", count: 20 },
    { name: "privateNetworks", label: "Private Networks", count: 100 },
  ],
  lessonlearned: [
    { name: "type", label: "Type" },
    { name: "createdYear", label: "Creation Year", count: 20 },
    { name: "created", label: "Creation Month", count: 20 },
    { name: "status", label: "Status" },
    { name: "country", label: "Country", count: 100 },
    { name: "project", label: "Project", count: 100 },
    { name: "categoryL1", label: "Category", count: 100 },
    { name: "categoryL2", label: "Technical Category", count: 100 },
    { name: "categoryL3", label: "Technical SubCategory", count: 100 },
    { name: "validatingFunctionL1", label: "Validation Category", count: 100 },
    {
      name: "validatingFunctionL2",
      label: "Validation SubCategory",
      count: 100,
    },
  ],
  /*guestAttachments: [
    { name: "objectSubType", label: "Type" },
    { name: "objectCreatedYear", label: "Creation Year" },
    { name: "objectCreated", label: "Creation Month" },
    { name: "users", label: "Users", count: 100 },
    { name: "hashtags", label: "Hashtags", count: 20 },
    { name: "fileType", label: "File Type", count: 20 },
    { name: "privateNetworks", label: "Private Networks", count: 100 }
  ],*/
  webinars: [
    { name: "date", label: "Webinar date" },
    { name: "visibility", label: "Type" },
    { name: "promotingNetworks", label: "Organizing Networks", count: 80 },
    { name: "internalSpeakers", label: "Internal Speakers", count: 100 },
    { name: "topics", label: "Topics" },
    { name: "keywords", label: "Keywords" },
    ///{ name: "privateNetworks", label: "Private Networks", count: 100 }
    // { name: "createdYear", label: "Creation Year" },
    // { name: "created", label: "Creation Month" },
    // { name: "hashtags", label: "Hashtags" },
    // { name: "invitedCoPs", label: "invited CoPs" },
    // { name: "involvedPeople", label: "Involved People" },
    // { name: "relatedHashtags", label: "Related Hashtags" },
    // { name: "remoteSpeaker", label: "Remote Speaker" },
  ],
  /*guestWebinars: [
    { name: "date", label: "Webinar date" },
    { name: "visibility", label: "Type" },
    { name: "promotingNetworks", label: "Organizing Networks", count: 80 },
    { name: "internalSpeakers", label: "Internal Speakers", count: 100 },
    { name: "topics", label: "Topics" },
    { name: "keywords", label: "Keywords" },
    { name: "privateNetworks", label: "Private Networks", count: 100 }
  ],*/
  bookings: [
    { name: "date", label: "Webinar date" },
    { name: "visibility", label: "Type" },
    { name: "promotingNetworks", label: "Organizing Networks", count: 80 },
    { name: "internalSpeakers", label: "Internal Speakers", count: 100 },
    // { name: "createdYear", label: "Creation Year" },
    // { name: "created", label: "Creation Month" },
    // { name: "hashtags", label: "Hashtags" },
    // { name: "invitedCoPs", label: "invited CoPs" },
    // { name: "involvedPeople", label: "Involved People" },
    // { name: "relatedHashtags", label: "Related Hashtags" },
    // { name: "remoteSpeaker", label: "Remote Speaker" },
  ],
};

export const INDEX_OPTIONS = {
  default: {
    additionalFilter: `type ne 'Booking'`,
  },
  hashtags: {
    additionalFilter: "((typeId eq 1 or typeId eq 5) and isPrivate eq false)",
  },
  networks: {
    additionalFilter: `(typeId ne ${NetworkType.External} and isPrivate eq false)`,
  },
  attachments: {
    top: 20,
    select: [
      "metadata_storage_path",
      "fileurl",
      "mimeType",
      "objectId",
      "objectName",
      "objectSubType",
      "objectLogoUrl",
      "userIds",
      "users",
      "hashtagIds",
      "hashtags",
      "objectCreated",
      "filename",
    ],
    additionalFilter: `(objectId ne null)`,
  },
  booking: {
    additionalFilter: "status ne 'Locked'",
  },
  webinars: {
    top: 50,
    select: [
      "hashtagId",
      "hashtag",
      "promotingNetworks",
      "promotingNetworksIds",
      "involvedPeople",
      "involvedPeopleIds",
      "status",
      "date",
      "type",
      "webinarMonth",
      "webinarYear",
      "visibility",
      "internalSpeakers",
      "internalSpeakersIds",
      "id",
      "name",
      "created",
      "createdYear",
      "modified",
      "modifiedYear",
      "url",
      "promotingNetworksTypes",
    ],
    highlightFields: [
      "hashtag",
      "promotingNetworks",
      "involvedPeople",
      "status",
      "type",
      "visibility",
      "internalSpeakers",
      "name",
      "url",
      "transcripts",
    ],
    additionalFilter: "id ne null",
  },
};
